* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-size: 1.4rem;
}

h1 { 
  font-size: 9vw;
  line-height: 9vw; 
  color:white;
  text-align: center;
}

h2 { 
  font-size: 4.5vw;
  line-height: 4.5vw; 
  color:white;
  text-align: center;
}

h3 { 
  font-size: 2.5vw;
  line-height: 5vw; 
  color:white;
  text-align: center;
}

h4 { 
  font-size: 2.7vw;
  line-height: 4vw; 
  color:black;
  text-align: left;
}

@media (min-width: 350px) and (max-width: 440px) {
  h1 { 
    font-size: 13vw;
    line-height: 13vw; 
    color:white;
    text-align: center;
  }
  
  h2 { 
    font-size: 7.5vw;
    line-height: 7.5vw; 
    color:white;
    text-align: center;
  }

  .intro-p {
    font-size: 7.5vw;
    line-height: 7.5vw; 
  }
  
  h3 { 
    font-size: 4.3vw;
    line-height: 8.5vw; 
    color:white;
    text-align: center;
  }
}

@media (max-width: 350px) {
  h1 { 
    font-size: 13vw;
    line-height: 13vw; 
    color:white;
    text-align: center;
  }
  
  h2 { 
    font-size: 7.4vw;
    line-height: 7.4vw; 
    color:white;
    text-align: center;
  }

  .intro-p {
    font-size: 7.4vw;
    line-height: 7.4vw; 
  }
  
  h3 { 
    font-size: 4.3vw;
    line-height: 8.5vw; 
    color:white;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }
}