.cv-card {
    min-height: 50vh;
    border-top: 8px solid #000;
    display: flex;
    flex-direction: column;
}

.cv-img {
    height: 55vh;
    width: 100%;
    background: url("/public/steffen2.jpg") no-repeat left center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: auto 150%;
    background-attachment:fixed;
}

.cv-card-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.cv-card-header>h3 {
    font-size: 2rem;
    line-height: 3.5rem;
    text-align: left;
    color: #000;
}

.cv-card-header>h4 {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: left;
    color: #000;
}

.cv-card>h3 {
    font-size: 2rem;
    line-height: 3.5rem;
    text-align: left;
    color: #000;
}

.cv-card>h4 {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: left;
    color: #000;
}

.time {
    position:relative;
    padding-top:20px;
    display:inline-block;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    z-index: 0;
}

.time-noclick {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.time-range{
    width:150px;
}

.contact {
    padding-top: 10px;
}

.description {
    padding-top: 10px;
    hyphens: auto; 
	text-align: justify;
}

.badge{
    position: absolute;
    width:100px;
    height:100px;
    right:-20px;
    top:-60px;
}

.stelle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 300px;
}

@media (min-width: 1536px){
    
    .cv-img {
        background-position: 120% -30%;
    }
}

@media (min-width: 1200px) and (max-width: 1536px){
    
    .cv-img {
        background-position: -12% 7%;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    
    .cv-img {
        background-position: 20% 7%;
    }

    .badge{
        position: absolute;
        width:100px;
        height:100px;
        right:calc(100% - 330px);
        top:-60px;
    }
}

@media (min-width: 440px) and (max-width: 900px) {
    
    .cv-img {
        background-position: 20% 7%;
    }

    .badge{
        position: absolute;
        width:100px;
        height:100px;
        right:calc(100% - 330px);
        top:-60px;
    }
}

@media (min-width: 350px) and (max-width: 440px) {

    .badge{
        position: absolute;
        width:100px;
        height:100px;
        right:calc(100% - 330px);
        top:-63px;
    }
    
    .cv-card>h3 {
        font-size: 1.9rem;
        line-height: 3.5rem;
    }

    .cv {
        padding: 2px;
    }

    .time {
        flex-direction: column;
        padding-bottom: 15px;
    }

    .cv-img {
        background-position: 30% 7%;
    }
}

@media (max-width: 350px) {
    .cv-card>h3 {
        font-size: 1.9rem;
        line-height: 3.5rem;
    }

    .cv {
        padding: 2px;
    }

    .time {
        flex-direction: column;
        padding-bottom: 15px;
    }

    .cv-img {
        background-position: 30% 0%;
    }

    .stelle {
        width:270px;
    }
}
