.backdrop-main {
    background-color: white;
    border: 0px solid white;
    border-radius: 10px;
    width:75vw;
    height: 75vh;
    padding: 2vw;
    overflow-y: scroll;
}

.backdrop-main p {
    color:black;
}

.summary {
    width:100%;
    border-top: 8px solid #000;
    margin-top: 16px;
    padding-top: 1vh;
}

.tag-container {
    display:flex;
    flex-wrap: wrap;
}

.tech-tag {
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 2px solid black;
    border-radius: 100px;
    margin-right:2px;
    margin-top:4px;
    white-space: initial;
  }