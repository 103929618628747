.home {
    width:100%;
    height: 100vh;
/*     background: url("/public/steffen.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.home::after{
    content:"";
    position:fixed; 
    height:100vh; 
    left:0;
    right:0;
    z-index:-10; 
    background: url("/public/steffen.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mouse-div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mouse-div {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 440px) and (max-width:1050px) {
    .home::after {
        background-position : 12% 100%;
    }
}

@media (max-width: 440px) {
    .home::after {
        background-position : 6% 100%;
    }

    .inner-content {
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 0 50px 0px;
    }
  }

@keyframes scroll {
    0% {
        transform: translateY(0);
   }
    30% {
        transform: translateY(60px);
   }
}
svg #wheel {
    animation: scroll ease 2s infinite;
}
*, *::before, *::after {
    box-sizing: border-box;
}

