.intro {
  min-height: 100vh;
  display: flex;
  padding-left: 10vw;
  padding-right: 10vw;
  flex-direction: column;
  justify-content: center;
}

.intro-p {
  font-size: 4.5vw;
  line-height: 4.5vw;
  text-align: center;
  font-family: 'Poppins';
  font-weight: bold;
  color: black;
  line-height: 5vw;
}

.tag {
  font-size: 1.8vw;
  border: 2px solid black;
  padding-top: .4rem;
  padding-bottom: .4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 100px;
  font-weight: 200;
  white-space: nowrap;
  vertical-align: middle;
}

.shaker {
  display: inline-block;
  animation: shake 1s;
  animation-iteration-count: 4;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(-7deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(0deg);
  }

  70% {
    transform: rotate(-9deg);
  }

  80% {
    transform: rotate(-6deg);
  }

  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 900px) {
  .tag {
    padding-top: .2rem;
    padding-bottom: .2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 350px) and (max-width: 440px) {
  .intro {
    padding-left: 20px;
    padding-right: 20px;
  }

  .intro-p {
    font-size: 7.5vw;
    line-height: 10vw;
  }

  .tag {
    font-size: 4vw;
  }

}

@media (max-width: 350px) {

  .intro {
    padding-left: 20px;
    padding-right: 20px;
  }

  .intro-p {
    font-size: 7.4vw;
    line-height: 10vw;
  }

  .tag {
    font-size: 4vw;
  }


}